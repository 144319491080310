import React, { useContext, useEffect, useMemo, useState } from "react";
import { Alert, Button, Col, Form } from "react-bootstrap";
import WalletItem from "./WalletItem";
import Authenticator from "../../services/Authenticator";
import { NotificationContext } from "../../components/notification/Notification";

import "./Wallet.scss";

const Wallet = ({ userData }) => {
    const [isDegensOnBaseNFTHolder, setIsDegensOnBaseNFTHolder] = useState(false); //TODO REPLACE WHEN PROPERTY FROM BE
    const [degensOnBaseNFTWalletAddress, setDegensOnBaseNFTWalletAddress] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const { notify } = useContext(NotificationContext);

    useEffect(() => {
        if (userData?.dgnbNftHolderAddress) {
            setDegensOnBaseNFTWalletAddress(userData.dgnbNftHolderAddress);
        }
    }, [userData]);

    const headlineDOM = useMemo(() => {
        return (
            <Col xs={12} className="charities">
                <h2 className="h6 uppercase sectionHeader">Deposit Information</h2>
                <h3 className="h5">Your Wallet Address</h3>
            </Col>
        );
    }, []);

    const notificationDOM = (
        <Alert variant={"warning"}>
            <p>
                To be safe, create a new wallet address specifically for receiving token
                rewards.
            </p>
        </Alert>
    );

    const handleSubmit = async () => {
        setIsButtonDisabled(true);
        try {
            await Authenticator.updateAttributes({
                dgnbNftHolderAddress: degensOnBaseNFTWalletAddress,
            })

            notify({
                message: 'Your Degens on Base NFT holder wallet address was saved!',
                type: 'success'
            });
        } catch (error) {
            notify({
                message: 'Something went wrong. Please try again later.',
                type: 'critical'
            })
            setIsButtonDisabled(false);
        }
    }

    const handleChange = (event) => {
        setDegensOnBaseNFTWalletAddress(event.target.value);
        setIsButtonDisabled(!event.target.value?.length);
    }

    return (
        <>
            {notificationDOM}
            {headlineDOM}
            <WalletItem
                type="ethereum"
                title={"Ethereum/ERC 20 Wallet (ex: Metamask, Coinbase Wallet)"}
                walletAddress={userData?.ethereumWallet}
            />
            <WalletItem
                type="solana"
                title={"Solana Wallet (ex: Solflare)"}
                walletAddress={userData?.solanaWallet}
            />

            <h3 className="h5 mt-5">NFT Collection</h3>
            <Form>
                <Form.Group controlId={"degensOnBaseNFTHolderWallet"}>
                    <Form.Check
                        type={"checkbox"}
                        label={"Degens on Base NFT holder"}
                        onChange={() =>
                            setIsDegensOnBaseNFTHolder(!isDegensOnBaseNFTHolder)
                        }
                        checked={userData?.dgnbNftHolderAddress}
                        name={"degensOnBaseNFTHolderWallet"}
                        className="custom-checkbox mt-2"
                    />
                </Form.Group>

                {isDegensOnBaseNFTHolder || userData?.dgnbNftHolderAddress ? (
                    <div style={{paddingLeft: '2rem'}}>
                        <Form.Control
                            value={degensOnBaseNFTWalletAddress}
                            onChange={handleChange}
                            placeholder="Degens on Base NFT holder wallet address"
                            className="mt-3"
                            autoFocus={isDegensOnBaseNFTHolder}
                            required={true}
                        />
                        <Button 
                            variant="primary" 
                            className='mt-4' 
                            type="submit" 
                            disabled={isButtonDisabled} 
                            onClick={() => (async () => await handleSubmit())()}
                        >
                            Save
                        </Button>
                    </div>
                ) : null}
            </Form>
        </>
    );
};

export default Wallet;
